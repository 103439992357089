import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import Video from 'src/components/Video/Video'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { getContentfulSrcSet } from 'src/utils/image'

import styles from './Hero.module.scss'

import type { ContentfulHero } from 'src/apollo/types'

const ConditionalLinkWrapper = ({ condition, wrapper, children }: any) => {
  return condition ? wrapper(children) : children
}

const Hero = ({ images, link, textPosition, title }: ContentfulHero) => {
  const mainImage = images && images.length > 0 && images[0] ? images[0] : 0
  const rightImage = images && images.length > 1 && images[1] ? images[1] : 0
  const positions = textPosition ? textPosition.split('/') : ['', '']
  const hasSpecialChars = !!title?.match(/[ÅÄÖ]/)
  const textDivs = title.split(' ')

  return rightImage && rightImage.url ? (
    <Flex
      className={styles.splitWrapper}
      as="section"
      style={{ position: 'relative' }}
    >
      <ConditionalLinkWrapper
        condition={link}
        wrapper={(children: any) => (
          <LinkComponent href={link?.slug ? link?.slug : ''}>
            {children}
          </LinkComponent>
        )}
      >
        <>
          <Box width={[1, null, null, 1 / 2]} className={styles.midWrapper}>
            {mainImage &&
            mainImage.url &&
            mainImage.contentType &&
            mainImage.contentType.includes('video') ? (
              <Video
                video={mainImage}
                isMuted={true}
                title={title ? title : ''}
              />
            ) : mainImage && mainImage.url ? (
              <Image
                srcSet={getContentfulSrcSet(mainImage.url)}
                critical
                sizes={{ mobile: '100vw', tablet: '100vw', desktop: '100vw' }}
                alt={title}
              />
            ) : null}
          </Box>
          <Box
            width={1 / 2}
            className={[styles.midWrapper, 'hideMobile'].join(' ')}
          >
            {rightImage &&
              rightImage.url &&
              rightImage.contentType &&
              (rightImage.contentType.includes('video') ? (
                <Video
                  video={rightImage}
                  isMuted={true}
                  title={title ? title : ''}
                />
              ) : (
                <Image
                  srcSet={getContentfulSrcSet(rightImage.url)}
                  critical
                  alt={title}
                />
              ))}
          </Box>
          <Flex
            paddingX={[5, null, null, 14]}
            paddingY={[7, null, null, 14]}
            alignItems={
              positions[0] === 'bottom'
                ? 'flex-end'
                : positions[0] === 'top'
                ? 'flex-start'
                : 'center'
            }
            justifyContent={
              positions[1] === 'left'
                ? 'flex-start'
                : positions[1] === 'right'
                ? 'flex-end'
                : 'center'
            }
            className={styles.content}
          >
            <Flex
              width={[1, null, null, 1]}
              maxWidth="500px"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Flex
                flexWrap="wrap"
                marginBottom={[3]}
                as="h1"
                className={styles.title}
              >
                {textDivs &&
                  textDivs.map((text: string, index: number) => (
                    <Box
                      className={[
                        styles.textItem,
                        hasSpecialChars ? styles.increaseLineHeight : '',
                      ].join(' ')}
                      marginRight={'0.21em'}
                      paddingX={'0.01em'}
                      as="span"
                      key={index}
                    >
                      <span style={{ animationDelay: `${index * 0.06}s` }}>
                        {text}
                      </span>
                    </Box>
                  ))}
              </Flex>
              {link && link.slug && (
                <div className={styles.cta}>{link.title}</div>
              )}
            </Flex>
          </Flex>
        </>
      </ConditionalLinkWrapper>
    </Flex>
  ) : (
    <Box as="section" className={styles.wrapper}>
      <ConditionalLinkWrapper
        condition={link}
        wrapper={(children: any) => (
          <LinkComponent href={link?.slug ? link?.slug : ''}>
            {children}
          </LinkComponent>
        )}
      >
        <>
          {mainImage &&
          mainImage.url &&
          mainImage.contentType &&
          mainImage.contentType.includes('video') ? (
            <Video
              video={mainImage}
              isMuted={true}
              title={title ? title : ''}
            />
          ) : mainImage && mainImage.url ? (
            <Image
              srcSet={getContentfulSrcSet(mainImage.url)}
              critical
              sizes={{ mobile: '100vw', tablet: '100vw', desktop: '100vw' }}
              alt={title}
            />
          ) : null}
          <Flex
            paddingX={[5, null, null, 14]}
            paddingY={[7, null, null, 18]}
            alignItems={
              positions[0] === 'bottom'
                ? 'flex-end'
                : positions[0] === 'top'
                ? 'flex-start'
                : 'center'
            }
            justifyContent={
              positions[1] === 'left'
                ? 'flex-start'
                : positions[1] === 'right'
                ? 'flex-end'
                : 'center'
            }
            className={styles.content}
          >
            <Flex
              width={[1, null, null, '60%']}
              alignItems="flex-start"
              flexDirection="column"
            >
              <Flex
                flexWrap="wrap"
                marginBottom={[3]}
                as="h1"
                className={styles.title}
              >
                {textDivs &&
                  textDivs.map((text: string, index: number) => (
                    <Box
                      className={[
                        styles.textItem,
                        hasSpecialChars ? styles.increaseLineHeight : '',
                      ].join(' ')}
                      marginRight={'0.21em'}
                      paddingX={'0.01em'}
                      as="span"
                      key={index}
                    >
                      <span style={{ animationDelay: `${index * 0.06}s` }}>
                        {text}
                      </span>
                    </Box>
                  ))}
              </Flex>
              {link && link.slug && (
                <LinkComponent className={styles.cta} href={link.slug}>
                  {link.title}
                </LinkComponent>
              )}
            </Flex>
          </Flex>
        </>
      </ConditionalLinkWrapper>
    </Box>
  )
}

export default Hero
